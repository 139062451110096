
import InfoPage from "@/components/InfoPage"
import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"

export default {
  components: {
    InfoPage,
  },
  meta: {
    public: true,
    pageId: AnalyticsEventPageId.cameraFeatureInfo,
  },
  middleware: "infoPagesRedirect",
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
  },
}
